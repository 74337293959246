import React, { useEffect, useState } from 'react';

const ImageComponent = () => {
  // Utiliza un estado para almacenar la URL de la imagen
  const [imageUrl, setImageUrl] = useState('');

  const handleImageClick = () => {
    window.location.href = 'https://oliveplus.cl';
  };

  // Función para actualizar la URL de la imagen en función del ancho de la ventana
  const updateImageUrl = () => {
    console.log("update image")
    console.log(window.screen.width)
    const newImageUrl = window.screen.width < 768 ? '/assets/images/m.png' : '/assets/images/desktop.png';
    console.log("newImageUrl",newImageUrl)
    setImageUrl(newImageUrl);
  };

  // Usa useEffect para registrar el evento de redimensionamiento y actualizar la imagen
  useEffect(() => {
    updateImageUrl(); // Llama a la función al cargar la página para establecer la imagen inicial

    window.addEventListener('resize', updateImageUrl);

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', updateImageUrl);
    };
  }, []);

  return (
    <div className="images">
      <a href="https://oliveplus.cl">
        <img
        className="img-fluid"
          src={imageUrl}
          
          alt="Mobile or Desktop"
          onClick={handleImageClick}
          style={{ cursor: 'pointer' }}
        />
      </a>
    </div>
  );
};

export default ImageComponent;
