
import ImageComponent from './components/ImageComponent';

function App() {
  return (
    <div className="App">
      <ImageComponent />
    </div>
  );
}


export default App;
